<template>
  <div>
    <vs-popup title="Filter" :active="isActive" :z-index="100001" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Group</label>
            <vs-select autocomplete class="w-full" v-model="data.group">
              <vs-select-item v-for="(it, index) in groups" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" color="danger" type="border" @click="clearFilter" :disabled="isEmptyFilter">Clear Filter</vs-button>
            <vs-button class="mr-3" color="primary" @click="filter" :disabled="isEmptyFilter">Filter</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CoaFilter',
  props: ['isActive'],
  watch: {
    isActive (active) {
      if (active && this.isFirstInit) {
        this.initData()
        this.isFirstInit = false
      }
    }
  },
  computed: {
    isEmptyFilter () {
      return _.isEmpty(this.data)
    }
  },
  data () {
    return {
      isFirstInit: true,
      data: {},
      groups: [
        'ASSETS',
        'LIABILITY',
        'EQUITY',
        'INCOME',
        'COST OF SALES',
        'EXPENSES',
        'OTHER INCOME',
        'OTHER EXPENSE',
        'STATISTIC'
      ]
    }
  },
  methods: {
    initData () {

    },

    filter () {
      this.$emit('filter', this.data)
      this.emitModalIsActive(false)
    },

    clearFilter () {
      this.data = {}
      this.$emit('filter', {})
      this.emitModalIsActive(false)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
